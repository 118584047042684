import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from 'react-helmet'
import { Text, Pane, ArrowLeftIcon, ListItem } from 'evergreen-ui';
import { Layout, DeveloperActivityTable, Link } from '../components/common'
import { MetaData } from '../components/common/meta'
import { titleCase } from 'title-case';


/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const DeveloperActivityPage = ({ data, location }) => {
    const { questionsSO, questionsSE, answersSE, usersSE, dateForSEO, dateForReader, imageSrc, title, description, currency, symbol, totals } = data.developerActivity;

    const page = { meta_title: title, title, description };
    const newData = { ghostPage: page };

    return (
        <>
            <MetaData
                data={newData}
                location={location}
                type="article"
                title={title}
                description={description}
                image={imageSrc}
            />
            <Layout location={location}>
                <div className="container developer-activity">
                    <article className="content">
                        <Pane className="post-full-content" marginBottom="3vw">
                            <Link to="/developer-activity/"><ArrowLeftIcon marginBottom={4} /> All Developer Activity</Link>
                        </Pane>
                        <figure className="post-feature-image">
                            <img src={imageSrc} alt={`${page.title} Logo`} />
                        </figure>
                        <section className="post-full-content">
                            <Text color="muted">Updated <time datetime={dateForSEO}>{dateForReader}</time></Text>
                            <h1 className="content-title">{title}</h1>
                            <section className="content-body load-external-scripts smaller-headings">
                                <p>
                                    This page helps give you insight into the {currency} ({symbol}) developer activity, by providing data from Stack Overflow and the {currency} Stack Exchange. <a target="_blank" rel="noopener" href="https://stackoverflow.com/">Stack Overflow</a> is a question and answer site for programmers. Devs can ask questions across many programming-related topics, such as {currency}, and other programmers can answer those questions.
                                </p>
                                <p>
                                    <a target="_blank" rel="noopener" href="https://stackexchange.com/">Stack Exchange</a> is a network of different Q & A sites, each focused on a specific topic. On the {currency} Stack Exchange, users can ask questions related to {currency} and others can answer.
                                </p>
                                Summary: 
                                <ul>
                                    <li><a href="#stack-exchange-questions">How Many Questions on the {currency} Stack Exchange?</a></li>
                                    <li><a href="#stack-exchange-answers">How Many Answers on the {currency} Stack Exchange?</a></li>
                                    <li><a href="#stack-exchange-users">How Many Users on the {currency} Stack Exchange?</a></li>
                                    <li><a href="#stack-overflow-questions">How Many {currency} Questions on Stack Overflow?</a></li>
                                    <li><a href="#why">Why Stack Overflow / Stack Exchange for {currency} Developer Activity?</a></li>
                                </ul>
                                
                                <h2 id="stack-exchange-questions">How Many Questions on the {currency} Stack Exchange?</h2>
                                <p>There have been {totals.questionsSE.toLocaleString()} total questions asked on the {currency} Stack Exchange.</p>
                                <p>This table shows the amount of questions asked each year:</p>
                                <DeveloperActivityTable total={totals.questionsSE} itemsPerYear={questionsSE} columnTitle={`${currency} Questions Asked`} />

                                <h2 id="stack-exchange-answers">How Many Answers on the {currency} Stack Exchange?</h2>
                                <p>There have been {totals.answersSE.toLocaleString()} total answers to questions on the {currency} Stack Exchange.</p>
                                <p>This table shows the amount of answers each year:</p>
                                <DeveloperActivityTable total={totals.answersSE} itemsPerYear={answersSE} columnTitle={`${currency} Answers`} />

                                <h2 id="stack-exchange-users">How Many Users on the {currency} Stack Exchange?</h2>
                                <p>There are {totals.usersSE.toLocaleString()} total users on the {currency} Stack Exchange.</p>
                                <p>This table shows the amount of users that joined the {currency} Stack Exchange each year:</p>
                                <DeveloperActivityTable total={totals.usersSE} itemsPerYear={usersSE} columnTitle={`${currency} Users`} />

                                <h2 id="stack-overflow-questions">How Many {currency} Questions on Stack Overflow?</h2>
                                {/* {!totals.questionsSO && <p>There are no blockchain-related Stack Overflow Questions with the tag <em>{currency}</em>.</p>} */}
                                {!totals.questionsSO && <p>There is no blockchain-related tag for <em>{currency}</em> questions on Stack Overflow.</p>}
                                {totals.questionsSO && <>
                                    <p>There have been {totals.questionsSO.toLocaleString()} total {currency} questions asked on Stack Overflow (questions asked with the tag <em>{symbol === 'EOS' ? symbol : currency}</em>).</p>
                                <p>This table shows the amount of {currency} questions asked on Stack Overflow each year:</p>
                                <DeveloperActivityTable total={totals.questionsSO} itemsPerYear={questionsSO} columnTitle={`${symbol === 'EOS' ? symbol : currency} Tagged Questions`} />
                                </>}

                                <h2 id="why">Why Stack Overflow / Stack Exchange for {currency} Developer Activity?</h2>
                                <p>Stack Overflow and Stack Exchange have a huge network of professionals, experts, and enthusiasts. Millions of developers / software engineers use these sites to ask questions and get answers. The amount of {currency} questions, answers, and users on these sites might provide some insight into how active and healthy the {currency} developer ecosystem is.</p>
                                <p>
                                    <Pane marginX="auto" maxWidth={400}>
                                        <Pane padding={20}>
                                            <img src="https://stackoverflow.design/assets/img/logos/so/logo-stackoverflow.svg" alt={`Stack Overflow Logo`} />
                                        </Pane>
                                        <Pane padding={20}>
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/7/75/Stack_Exchange_logo_and_wordmark.svg" alt={`Stack Exchange Logo`} />
                                        </Pane>
                                    </Pane>
                                </p>
                            </section>
                            <Text>Source: <a target="_blank" rel="noopener" href="https://api.stackexchange.com/">Stack Exchange API</a></Text>
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    )
}

export default DeveloperActivityPage;

export const postQuery = graphql`
    query($slug: String!) {
        developerActivity(slug: {eq: $slug}) {
            slug
            dateForSEO
            dateForReader
            imageSrc
            title
            description
            currency
            symbol
            questionsSO {
                year
                yearCount
            }
            answersSE {
                year
                yearCount
            }
            questionsSE {
                year
                yearCount
            }
            usersSE {
                year
                yearCount
            }
            totals {
                questionsSO
                questionsSE
                answersSE
                usersSE
              }
        }
    }
`;